
@font-face {
    font-family: 'Toyota Text';
    src: url('~@/assets/fonts/ToyotaText/ToyotaTextRg.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ToyotaText/ToyotaTextRg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ToyotaText/ToyotaTextRg.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaText/ToyotaTextRg.woff2') format('woff2'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaText/ToyotaTextRg.svg') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Toyota Text';
    src: url('~@/assets/fonts/ToyotaText/ToyotaTextBold.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ToyotaText/ToyotaTextBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ToyotaText/ToyotaTextBold.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaText/ToyotaTextBold.woff2') format('woff2'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaText/ToyotaTextBold.svg') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Toyota Display';
    src: url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayRg.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayRg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayRg.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayRg.woff2') format('woff2'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayRg.svg') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Toyota Display';
    src: url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayBd.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayBd.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayBd.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayBd.woff2') format('woff2'), /* Modern Browsers */
         url('~@/assets/fonts/ToyotaDisplay/ToyotaDisplayBd.svg') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

