@layer components {
  .button {
    @apply inline-block px-4 pt-2 pb-2 whitespace-nowrap;
    @apply font-toyota-display text-lg text-white leading-tight bg-brand-a border-brand-a border cursor-pointer;
    /*padding-bottom: .7rem;*/
    transition: opacity 0.2s ease;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .button--full {
    @apply w-full text-center;
  }

  .button--pill {
    @apply rounded-full;
  }

  .button--narrow {
    @apply py-2;
  }

  .button--no-trans {
    transition: none;
  }

  .button--uppercase {
    @apply pt-3 pb-3 uppercase text-sm tracking-tight;
  }

  .button--arrow {
    @apply pr-10;
    background-image: url(~@/assets/svg/icons/arrow-h-w.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-size: 7px;
  }

  .button--inverted {
    @apply text-brand-a bg-white border-brand-a border;

    &:hover {
      @apply text-white bg-brand-a;
    }
  }
}

