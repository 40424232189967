.icon-info {
  @apply inline-block w-4 h-4 mr-2;
  background-image: url(~@/assets/svg/icons/info.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.icon-checkmark {
  @apply inline-block w-4 h-4 mr-2;
  background-image: url(~@/assets/svg/icons/checkmark-dark.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.w-icon-checkmark {
  @apply pl-6 relative;

  &:before {
    @apply block absolute left-0 top-0 mt-1;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(~@/assets/svg/icons/checkmark-red.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
