.inquiry-form {
  & p {
    @apply font-light;
  }

  & label {
    @apply font-toyota-display font-bold text-gray-600;
  }

  & fieldset legend {
    @apply font-toyota-display font-bold text-lg text-gray-600;
  }

  & input[type="text"]:not(.multiselect__input),
  & textarea {
    @apply
      rounded-lg bg-gray-200 text-gray-800 shadow-inner-dark
      py-3 pb-3 px-5;

    &::placeholder {
      @apply text-gray-500;
    }

    &:focus {
      @apply outline-none text-black;
    }

    &.valid:focus {
      @apply border-black;
    }

    &.invalid {
      @apply border-red-500;
    }
  }

  & .radio,
  & .checkbox {
    & label {
      @apply text-gray-800 font-light;
    }

    & label:before {
      @apply w-6 h-6 bg-white mr-3 rounded-full border-2 border-brand-a;
    }

    & input:checked + label:before {
      background: radial-gradient(theme('colors.brand.a') 0%, theme('colors.brand.a') 50%, white 58%, white 100%), white;
    }

    &.checkbox input:checked + label:before {
      background-image: url(~@/assets/svg/icons/checkmark-red.svg);
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: 50% 50%;
    }
  }

  & .flatpickr-input {
    @apply appearance-none block w-full bg-gray-200 rounded-lg text-gray-800 shadow-inner-dark py-3 pb-3 px-5;

    &::placeholder {
      @apply text-gray-500;
    }
  }

  & .flatpickr-calendar {
    background-color: #dfdfdf;
    border-top: 6px solid theme('colors.brand.a');

    &.arrowTop::after {
      border-bottom-color: theme('colors.brand.a');
      border-width: 20px;
    }

    &.arrowBottom::after {
      border-top-color: #dfdfdf;
      border-width: 20px;
    }
  }
}

@screen sm {
  .inquiry-form__inner {
    @apply px-4 py-4;
  }
}

.inquiry-form__submit {
  @apply button button--narrow button--pill uppercase tracking-tight font-light flex justify-center items-center;
}

.inquiry-form__error {
  @apply text-red-500 text-xs absolute mt-px;
}

.inquiry-form__info-panel {
  @apply px-4 py-3 text-md rounded bg-gray-300;
}

.inquiry-form__vehicle-title {
  @apply text-gray-600 font-toyota-display font-bold text-4xl;
}

.multiselect {
  @apply text-gray-600;

  &.invalid {
    @apply border-red-500;
  }
}

.multiselect__tags {
  @apply rounded-lg bg-gray-200 text-gray-800 shadow-inner-dark;
}

.multiselect__single,
.multiselect__input {
  background: none;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--highlight {
  background-color: #eeeeee;
  @apply text-gray-600;
}

.multiselect__option--selected {
  @apply bg-brand-a text-white;
}
